<script setup lang="ts">
  import { useGetAccount } from "@/api/account/account"
  import { ExternalRoute } from "@/constants/externalRoute.ts"
  import { aggregateQueries } from "@/lib/aggregateQueries"
  import { useGetAccountId } from "@/lib/useGetAccountId"
  import { formatCurrency } from "@/lib/utils"
  import { computed } from "vue"
  import ErrorMessage from "@/components/ErrorMessage.vue"
  import { Button } from "@/components/ui/button"
  import {
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    CardTitle,
  } from "@/components/ui/card"

  import BalanceCardSkeleton from "./BalanceCardSkeleton.vue"

  const accountIdQuery = useGetAccountId()
  const accountQuery = useGetAccount(accountIdQuery.accountId)

  const { isError, isSuccess, isLoading } = aggregateQueries([
    accountIdQuery,
    accountQuery,
  ])

  const balanceValue = computed(() => accountQuery.data.value?.balance)

  const formattedBalance = computed(() => {
    if (balanceValue.value === undefined) return ""
    return formatCurrency(Math.abs(balanceValue.value))
  })

  const balanceType = computed(() => {
    if (!balanceValue.value) return ""
    return balanceValue.value < 0 ? "in credit" : "in debit"
  })
</script>

<template>
  <BalanceCardSkeleton v-if="isLoading" />
  <Card v-else data-testid="balance-card">
    <CardHeader>
      <CardTitle>Your Balance</CardTitle>
    </CardHeader>
    <CardContent>
      <ErrorMessage v-if="isError" />
      <p v-else class="flex items-baseline" data-testid="balance-display">
        <span class="h2">{{ formattedBalance }}</span>
        <span class="body-text ml-2">{{ balanceType }}</span>
      </p>
    </CardContent>
    <CardFooter>
      <Button variant="tonal" :as-child="isSuccess" :disabled="!isSuccess">
        <span v-if="!isSuccess">Make a payment</span>
        <a v-else :href="ExternalRoute.makePayment">Make a payment</a>
      </Button>
    </CardFooter>
  </Card>
</template>
