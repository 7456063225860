<script setup lang="ts">
  import { TooltipTrigger, type TooltipTriggerProps } from "radix-vue"
  import Icon from "@/components/ui/icons/Icon.vue"

  const props = defineProps<TooltipTriggerProps>()
</script>

<template>
  <TooltipTrigger
    v-bind="props"
    data-testid="plain-tooltip-trigger"
    class="focus:outline-primary-400"
  >
    <slot>
      <Icon
        name="Info"
        class="-mb-0.5 ml-0.5"
        data-testid="plain-tooltip-trigger-icon"
      />
    </slot>
  </TooltipTrigger>
</template>
