type CookieCategory = "necessary" | "preferences" | "statistics" | "marketing"

export const openPreferencesModal = () => {
  window.Cookiebot?.renew()
}

export const runOnceCookieCategoryAccepted = (
  category: CookieCategory,
  callback: () => void,
) => {
  if (window.Cookiebot?.consent[category]) {
    callback()
    return
  }

  window.addEventListener(
    "CookiebotOnAccept",
    () => {
      if (window.Cookiebot?.consent[category]) {
        callback()
      }
    },
    false,
  )
}
