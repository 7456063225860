<script setup lang="ts">
  import { useGetAccount } from "@/api/account/account"
  import { aggregateQueries } from "@/lib/aggregateQueries.ts"
  import { useGetAccountId } from "@/lib/useGetAccountId.ts"
  import { useSwitchAccountModal } from "@/lib/useSwitchAccountModal"
  import { computed } from "vue"
  import ErrorMessage from "@/components/ErrorMessage.vue"
  import Button from "@/components/ui/button/Button.vue"
  import { Card } from "@/components/ui/card"

  import UserInfoCardSkeleton from "./UserInfoCardSkeleton.vue"

  const accountIdQuery = useGetAccountId()
  const accountQuery = useGetAccount(accountIdQuery.accountId)

  const { hasMultipleAccounts, openModal } = useSwitchAccountModal()

  const { isError, isLoading } = aggregateQueries([
    accountIdQuery,
    accountQuery,
  ])

  const addressString = computed(() => {
    const addressObject = accountQuery.data.value?.supplyAddress
    if (!addressObject) return "-"
    return [
      addressObject?.addressLine1,
      addressObject?.addressLine2,
      addressObject?.addressLine3,
      addressObject?.postcode,
    ]
      .filter(Boolean)
      .join(", ")
  })
</script>
<template>
  <UserInfoCardSkeleton v-if="isLoading" />
  <Card
    v-else
    class="flex w-full flex-col rounded border-primary-400 bg-primary-300 p-4 text-secondary-700 sm:w-full sm:max-w-full sm:flex-row sm:justify-between sm:gap-5 sm:p-6 md:min-h-[286px] md:flex-col md:items-start md:justify-start md:gap-0 md:text-left"
    data-testid="user-info-card"
  >
    <div class="flex flex-grow flex-col justify-center md:justify-normal">
      <h2
        class="hidden flex-wrap sm:flex sm:flex-row"
        data-testid="user-info-card_greeting"
      >
        <span>Welcome back,&nbsp;</span>
        <span>{{ accountIdQuery.data.value?.firstName }}!</span>
      </h2>
      <div
        v-if="hasMultipleAccounts"
        class="mt-6 hidden flex-grow items-center sm:inline-flex md:my-6"
      >
        <Button variant="tonal" @click="openModal"> Switch account </Button>
      </div>
    </div>

    <ErrorMessage v-if="isError" text-size="small" />
    <dl
      v-else
      class="body-text sm:label-text md:body-text flex flex-col justify-center gap-1 md:gap-2"
    >
      <div class="flex">
        <dt>Account number:&nbsp;</dt>
        <dd data-testid="user-info-card_account-number">
          {{ accountQuery.data.value?.accountNumber || "-" }}
        </dd>
      </div>
      <p data-testid="user-info-card_address">
        {{ addressString }}
      </p>
    </dl>
  </Card>
</template>
