import ErrorReporter from "@/services/ErrorReporter"
import { computed, unref, type MaybeRef } from "vue"

export const useQueryOptions = <TOptions>(
  options: TOptions,
  params: { [key: string]: MaybeRef },
): TOptions => {
  return {
    retry: false,
    enabled: computed(() => Object.values(params).every((v) => !!unref(v))),
    throwOnError: (error: Error) => {
      ErrorReporter.report(error)
      return false
    },
    ...options,
  } as const
}
