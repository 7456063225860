<script setup lang="ts">
  import { DefaultDateFormat } from "@/constants/date"
  import { fieldRequiredMessage } from "@/constants/errorMessages"
  import {
    alphabeticRegex,
    dateStringSlashesRegex,
    phoneRegex,
  } from "@/lib/regexHelper"
  import { useCotMoveIn } from "@/lib/useCotMoveIn"
  import { useStepWizard } from "@/lib/useStepWizard"
  import { toTypedSchema } from "@vee-validate/zod"
  import dayjs from "dayjs"
  import advancedFormat from "dayjs/plugin/advancedFormat"
  import customParseFormat from "dayjs/plugin/customParseFormat"
  import { useForm } from "vee-validate"
  import * as z from "zod"
  import Button from "@/components/ui/button/Button.vue"
  import Checkbox from "@/components/ui/inputs/checkbox/Checkbox.vue"
  import DateInput from "@/components/ui/inputs/date-input/DateInput.vue"
  import TextInput from "@/components/ui/inputs/text-input/TextInput.vue"
  import { StepWizardProgressCard } from "@/components/ui/step-wizard"

  const { goNext } = useStepWizard()
  const { state, updateDetails } = useCotMoveIn()

  dayjs.extend(customParseFormat)
  dayjs.extend(advancedFormat)

  const formSchema = toTypedSchema(
    z.object({
      firstName: z
        .string()
        .min(1, fieldRequiredMessage)
        .regex(
          alphabeticRegex,
          "Name can only include letters and spaces. No numbers or special characters",
        )
        .min(2, "Name must be at least 2 characters long"),
      lastName: z
        .string()
        .min(1, fieldRequiredMessage)
        .regex(
          alphabeticRegex,
          "Name can only include letters and spaces. No numbers or special characters",
        )
        .min(2, "Name must be at least 2 characters long"),
      email: z
        .string()
        .min(1, fieldRequiredMessage)
        .email("Please enter a valid email"),
      phone: z
        .string()
        .min(1, fieldRequiredMessage)
        .regex(phoneRegex, "Please enter a valid phone number"),
      dateOfBirth: z
        .string({
          required_error: "Please enter a valid date in the format DD/MM/YYYY",
        })
        .regex(
          dateStringSlashesRegex,
          "Please enter a valid date in the format DD/MM/YYYY",
        )
        .refine(
          (date) =>
            dayjs(date, DefaultDateFormat).isAfter(
              dayjs().subtract(150, "year"),
            ) &&
            dayjs(date, DefaultDateFormat).isBefore(
              dayjs().subtract(16, "year"),
            ),
          "Please enter a valid date of birth",
        ),
      rewardsAndUpdates: z.boolean().default(false),
    }),
  )

  const { handleSubmit } = useForm({
    validationSchema: formSchema,
  })

  const onSubmit = handleSubmit(async (data) => {
    updateDetails(data)
    goNext()
  })
</script>

<template>
  <form
    class="flex w-full flex-wrap justify-center"
    novalidate
    @submit="onSubmit"
  >
    <StepWizardProgressCard title="Personal details">
      <div
        class="mb-4 grid grid-cols-1 justify-between gap-x-6 gap-y-6 sm:mb-6 sm:grid-cols-2 sm:gap-y-4"
      >
        <TextInput
          v-model="state.firstName"
          name="firstName"
          type="text"
          label="First name"
        />
        <TextInput
          v-model="state.lastName"
          name="lastName"
          type="text"
          label="Last name"
        />
        <TextInput
          v-model="state.email"
          name="email"
          autofocus
          type="email"
          autocomplete="email"
          label="Email"
        />
        <TextInput
          v-model="state.phone"
          name="phone"
          type="tel"
          autocomplete="tel"
          label="Phone"
        />
        <DateInput
          v-model="state.dateOfBirth"
          label="Date of birth"
          name="dateOfBirth"
        />
      </div>
      <Checkbox
        v-model:checked="state.rewardsAndUpdates"
        name="rewardsAndUpdates"
        label="I'd like to hear about rewards and receive updates from So Energy"
      />
      <template #footer>
        <Button variant="tonal" type="submit" size="md"> Next </Button>
      </template>
    </StepWizardProgressCard>
  </form>
</template>
