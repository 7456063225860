<script setup lang="ts">
  import { cn } from "@/lib/utils.ts"
  import { HTMLAttributes } from "vue"
  import { iconsMap } from "@/components/ui/icons/iconsMap.ts"
  import { IconVariants, iconVariants } from "@/components/ui/icons/index.ts"

  defineEmits<(e: "click", payload: MouseEvent) => void>()

  const props = defineProps<{
    size?: IconVariants["size"]
    variant?: IconVariants["variant"]
    class?: HTMLAttributes["class"]
    name: keyof typeof iconsMap
  }>()
</script>

<template>
  <component
    :is="iconsMap[props.name]"
    :class="cn(iconVariants({ variant, size }), props.class)"
    :data-testid="`icon-${props.name}`"
    fill="none"
    aria-hidden="true"
    @click="(e) => $emit('click', e)"
  />
</template>
