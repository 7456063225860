<script setup lang="ts">
  import { getInputAriaDescribedBy } from "@/lib/inputComponentUtils"
  import { cn } from "@/lib/utils"
  import { SupportingLabel } from "@/types/InputComponentTypes.ts"
  import { useVModel } from "@vueuse/core"
  import { computed, getCurrentInstance } from "vue"
  import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
  } from "@/components/ui/form"
  import FormSupportingLabel from "@/components/ui/form/FormSupportingLabel.vue"
  import { Icon } from "@/components/ui/icons"
  import { iconsMap } from "@/components/ui/icons/iconsMap.ts"

  const props = defineProps<{
    defaultValue?: string | number
    modelValue?: string | number
    label: string
    extraLabel?: string
    supportingLabels?: SupportingLabel[]
    name: string
    autofocus?: boolean
    type?: string
    autocomplete?: string
    icon?: keyof typeof iconsMap
    iconPosition?: "leading" | "trailing"
    iconAriaLabel?: string
    iconAriaExpanded?: boolean
    disabled?: boolean
    required?: boolean
    placeholder?: string
    keepSupportingLabelsOnError?: boolean
  }>()

  const emits = defineEmits<{
    (e: "update:modelValue", payload: string | number): void
    (e: "iconClicked"): void
  }>()
  const modelValue = useVModel(props, "modelValue", emits, {
    passive: true,
    defaultValue: props.defaultValue,
  })

  const hasOnIconClickedListener = computed(
    () => !!getCurrentInstance()?.vnode.props?.["onIconClicked"],
  )

  const getAriaDescribedBy = (
    formDescriptionId: string,
    formMessageId: string,
    error: string,
  ) =>
    getInputAriaDescribedBy(
      {
        supportingLabels: props.supportingLabels,
        extraLabel: props.extraLabel,
        error,
      },
      formDescriptionId,
      formMessageId,
    )
</script>

<template>
  <FormField
    v-slot="{ field }"
    v-model="modelValue"
    :name="name"
    :validate-on-blur="false"
    :validate-on-change="false"
    :validate-on-model-update="false"
  >
    <FormItem v-bind="$attrs">
      <FormControl
        v-slot="{
          error,
          formItemId,
          ariaInvalid,
          formDescriptionId,
          formMessageId,
        }"
      >
        <div class="flex items-baseline justify-between">
          <FormLabel v-if="label" data-testid="form-label">{{
            label
          }}</FormLabel>
          <span
            v-if="extraLabel"
            :id="formDescriptionId"
            class="label-text-strong text-secondary-500 dark:text-secondary-300"
            data-testid="form-extra-label"
            :aria-label="`${extraLabel}.`"
          >
            {{ extraLabel }}
          </span>
        </div>

        <div class="relative">
          <input
            :id="formItemId"
            v-bind="{ ...field }"
            :aria-describedby="
              getAriaDescribedBy(formDescriptionId, formMessageId, error)
            "
            :aria-invalid="ariaInvalid"
            :autofocus="autofocus"
            :autocomplete="autocomplete"
            :type="type"
            :placeholder="placeholder"
            :disabled="disabled"
            :aria-required="required"
            :class="
              cn(
                'body-text flex h-12 w-full rounded-sm border px-4 py-3 sm:h-13',
                'text-secondary-700 ring-primary-400 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-secondary-400 hover:border-secondary-400 focus-visible:border-primary-400 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:border-secondary-500 disabled:bg-secondary-300 disabled:text-secondary-400',
                'dark:border-secondary-400 dark:bg-secondary-500 dark:text-secondary-100 dark:ring-primary-300 dark:placeholder:text-secondary-400 dark:hover:border-secondary-200 dark:focus-visible:border-primary-300 dark:disabled:border-secondary-500 dark:disabled:bg-secondary-600 dark:disabled:text-secondary-400',
                modelValue && 'border-secondary-400',
                icon && iconPosition === 'leading' && 'pl-12',
                icon && iconPosition === 'trailing' && 'pr-12',
                error && [
                  'border-red-400 ring-red-400 hover:border-red-400 focus-visible:border-red-400',
                  'dark:border-red-300 dark:ring-red-300 dark:hover:border-red-300 dark:focus-visible:border-red-300',
                ],
              )
            "
            data-testid="form-input"
          />
          <button
            v-if="icon"
            :tabindex="hasOnIconClickedListener ? 0 : -1"
            type="button"
            :class="
              cn([
                'absolute top-1/2 -translate-y-1/2  focus-visible:outline-offset-2',
                'focus-visible:outline-primary-400',
                'dark:focus-visible:outline-primary-300',
                iconPosition === 'leading' && 'left-4',
                iconPosition === 'trailing' && 'right-4',
              ])
            "
            :aria-expanded="iconAriaExpanded"
            :aria-label="iconAriaLabel"
            data-testid="adornment-button"
            @click.prevent="$emit('iconClicked')"
          >
            <Icon
              :id="`${name}-icon-button`"
              :name="icon"
              size="lg"
              data-testid="adornment-button-icon"
              :aria-label="iconAriaLabel"
            />
          </button>
        </div>
        <template
          v-if="
            supportingLabels?.length && (keepSupportingLabelsOnError || !error)
          "
        >
          <FormSupportingLabel
            v-for="(supportingLabel, index) in supportingLabels"
            :id="`${formMessageId}-${index}`"
            :key="`${formMessageId}-${index}`"
            :supporting-label="supportingLabel"
            :data-testid="`form-supporting-label-${index}`"
          >
          </FormSupportingLabel>
        </template>
        <FormMessage />
      </FormControl>
    </FormItem>
  </FormField>
</template>
