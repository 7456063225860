<script setup lang="ts">
  import { useGetAccountStates } from "@/api/account-state/account-state"
  import { AuthenticateMutationError } from "@/api/authentication/authentication"
  import { ErrorCode } from "@/api/model/errorCode"
  import { SupplyState } from "@/api/model/supplyState"
  import { useLogin } from "@/authentication/authenticationService"
  import { ExternalRoute } from "@/constants/externalRoute"
  import { Route } from "@/constants/route"
  import { getRedirectUrl } from "@/lib/getRedirectUrl"
  import { nexusHomePageEnabled } from "@/lib/nexusHomePageEnabled.ts"
  import { useGetAccountId } from "@/lib/useGetAccountId"
  import { getMyAccountUrl } from "@/lib/websiteUrls"
  import { isServerError } from "@/plugins/datadog/datadogHelper"
  import TrackingService from "@/services/TrackingService"
  import { toTypedSchema } from "@vee-validate/zod"
  import { useForm } from "vee-validate"
  import { computed } from "vue"
  import { useRoute, useRouter } from "vue-router"
  import * as z from "zod"
  import Alert from "@/components/ui/alert/Alert.vue"
  import { AlertDescription } from "@/components/ui/alert/index.ts"
  import { Button } from "@/components/ui/button"
  import { Checkbox } from "@/components/ui/inputs/checkbox"
  import PasswordField from "@/components/ui/inputs/password-field/PasswordField.vue"
  import { TextInput } from "@/components/ui/inputs/text-input"
  import { Link } from "@/components/ui/link"

  const legacyMyAccountUrl = getMyAccountUrl()
  const login = useLogin()
  const accountIdQuery = useGetAccountId()
  const accountStatesQuery = useGetAccountStates(accountIdQuery.accountId)

  const currentRoute = useRoute()
  const router = useRouter()
  const formSchema = toTypedSchema(
    z.object({
      email: z
        .string({
          required_error: "Please enter a valid email",
          invalid_type_error: "Please enter a valid email",
        })
        .email("Please enter a valid email"),
      password: z
        .string({
          required_error: "Please enter a valid password",
          invalid_type_error: "Please enter a valid password",
        })
        .min(1, "Please enter a valid password"),
      extendedSession: z.boolean().default(false).optional(),
    }),
  )

  const { handleSubmit } = useForm({
    validationSchema: formSchema,
  })

  const loginErrorCode = computed(() => {
    return login.error.value?.response?.data.errorCode
  })

  const activationSuccess = computed(
    () => currentRoute.query.account_activated === "true",
  )

  const checkIfSwitchingIn = async () => {
    await accountIdQuery.refetch()
    await accountStatesQuery.refetch()
    const supply = accountStatesQuery.data.value?.supply
    return (
      supply === ("preOnboarding" as SupplyState) || // Use enum when BE's SO-21902 is completed
      supply === SupplyState.onboarding ||
      supply === SupplyState.onboardingAdditionalMeterpoint
    )
  }

  const onSubmit = handleSubmit(async (data) => {
    TrackingService.sendEvent("login-auth-attempt", {
      email: data.email,
      extendedSession: !!data.extendedSession,
    })

    try {
      await login.mutateAsync({
        data: {
          email: data.email,
          password: data.password,
          extendedSession: !!data.extendedSession,
        },
      })
      const redirectUrl = getRedirectUrl(currentRoute.query.redirect)
      const isNexusHomePageEnabled = nexusHomePageEnabled()
      TrackingService.sendEvent("login-auth-successful", {
        redirectUrl,
        loginIntoNexus: isNexusHomePageEnabled,
      })

      if (redirectUrl) {
        window.location.assign(decodeURIComponent(redirectUrl))
        return
      }
      if (isNexusHomePageEnabled) {
        const isSwitchingIn = await checkIfSwitchingIn()
        if (isSwitchingIn) {
          window.location.assign(ExternalRoute.progress)
          return
        }
        router.push("/")
        return
      }

      window.location.assign(legacyMyAccountUrl)
    } catch (error) {
      if (!isServerError(error)) {
        throw error
      }

      const typedError = error as AuthenticateMutationError
      const errorCode = typedError.response?.data?.errorCode
      const expectedErrorCodes: ErrorCode[] = [
        ErrorCode.invalidCredentials,
        ErrorCode.accountLocked,
      ]
      const isExpectedServerError =
        error.message === "Network Error" ||
        expectedErrorCodes.includes(errorCode as ErrorCode)

      if (!isExpectedServerError) {
        throw error
      }
    }
  })
</script>

<template>
  <section
    class="my-4 flex flex-col items-center justify-center gap-10 sm:my-11"
  >
    <h1 class="max-w-80 text-center text-4xl">
      Welcome to your So Energy account.
    </h1>
    <p>Please sign in to your account</p>

    <Alert
      v-if="activationSuccess"
      variant="success"
      class="text-left sm:max-w-md"
    >
      <AlertDescription>
        Your account has been activated. You can now log in below.
      </AlertDescription>
    </Alert>

    <form
      class="flex w-full flex-col items-center"
      data-testid="login-form"
      novalidate
      @submit="onSubmit"
    >
      <div class="w-full items-center sm:w-80">
        <div class="space-y-10 text-left">
          <TextInput
            label="Email address"
            name="email"
            autofocus
            type="email"
            autocomplete="email"
            data-testid="login-email-input"
          />
          <PasswordField
            label="Password"
            name="password"
            type="password"
            autocomplete="current-password"
            data-testid="login-password-input"
          />
        </div>
        <Checkbox
          label="Remember me"
          name="extendedSession"
          class="mb-10 mt-3"
        />
      </div>

      <Alert
        v-if="login.error.value"
        variant="error"
        class="mb-10 w-auto max-w-card text-left"
      >
        <AlertDescription>
          <template v-if="login.error.value?.message === 'Network Error'">
            Cannot reach the So Energy server
          </template>
          <template v-else-if="loginErrorCode === ErrorCode.invalidCredentials">
            Email or password is incorrect. Please try again.
          </template>
          <template v-else-if="loginErrorCode === ErrorCode.accountLocked">
            Your account has been locked for 5 minutes due to multiple failed
            login attempts. Please wait before trying again. If you've forgotten
            your password, you can
            <Link class="link text-primary-600" :to="Route.passwordReset"
              >reset it now</Link
            >.
          </template>
          <template v-else> Unknown error! </template>
        </AlertDescription>
      </Alert>
      <div class="flex items-center justify-center space-x-2">
        <Button
          :loading="login.isPending.value || login.isSuccess.value"
          type="submit"
          class="m-auto"
          data-testid="login-btn"
          data-dd-action-name="Nexus Log in Button"
          >Log in</Button
        >
      </div>

      <Link
        class="link mt-10 text-primary-600"
        :to="Route.passwordReset"
        data-testid="login-forgot-password-link"
        >Forgot your password?</Link
      >
    </form>

    <div class="max-w-[37.5rem] space-y-3 text-center">
      <p class="font-medium">New to So Energy?</p>
      <p>
        If you have a switch in progress and have not yet accessed your account,
        <Link
          class="link text-primary-600"
          :to="Route.activateAccount"
          data-testid="login-activation-link"
          >activate your account here</Link
        >
      </p>
    </div>
  </section>
</template>
