import * as authenticationApi from "@/api/authentication/authentication"
import { AuthenticationRequest, AuthenticationResponse } from "@/api/model"
import ErrorReporter from "@/services/ErrorReporter"

import DeferredPromise from "./DeferredPromise"
import { useAuthSession } from "./useAuthSession"

interface LogoutOptions {
  onSuccess: () => void
  onError: () => void
}

export const authSession = useAuthSession()
let sessionRefreshPromise: DeferredPromise | null = null

export function useLogin() {
  return authenticationApi.useAuthenticate({
    request: {
      withCredentials: true,
    },
    mutation: {
      onSuccess: (
        response: AuthenticationResponse,
        request: { data: AuthenticationRequest },
      ) => {
        authSession.setSessionData(response, request.data.extendedSession)
      },
    },
  })
}

export async function refreshToken() {
  try {
    const isSessionExtended = authSession.isLongLived()
    const data = await authenticationApi.refreshToken(
      {
        extendedSession: isSessionExtended,
      },
      { withCredentials: true },
    )
    authSession.setSessionData(data, isSessionExtended)

    if (!authSession.isAuthenticated.value) {
      ErrorReporter.report("Received expired token", {
        tokenExpiry: data.accessTokenExpiry,
      })
      authSession.clearSessionData()
    }
  } catch {
    authSession.clearSessionData()
  }
}

export async function ensureFreshTokenIfAuthenticated() {
  if (sessionRefreshPromise) {
    await sessionRefreshPromise.promise
  }
  if (
    authSession.hasRefreshTokenCookie() &&
    !authSession.isAuthenticated.value
  ) {
    try {
      sessionRefreshPromise = new DeferredPromise()
      await refreshToken()
    } finally {
      sessionRefreshPromise?.resolve()
      sessionRefreshPromise = null
    }
  }
}

export function useLogout(options: LogoutOptions) {
  return authenticationApi.useLogout({
    request: { withCredentials: true },
    mutation: {
      onSuccess: () => {
        authSession.clearSessionData()
        options?.onSuccess()
      },
      onError: (error) => {
        options?.onError()
        ErrorReporter.report(error)
      },
    },
  })
}
