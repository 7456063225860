<script setup lang="ts">
  import { cn } from "@/lib/utils.ts"
  import { NavbarItem } from "@/types/Navbar.ts"
  import {
    NavigationMenuItem,
    NavigationMenuList,
    NavigationMenuRoot,
    NavigationMenuViewport,
  } from "radix-vue"
  import { ref } from "vue"

  import DesktopItem from "@/views/navbar/items/desktop/DesktopItem.vue"
  import NestedDesktopItem from "@/views/navbar/items/desktop/NestedDesktopItem.vue"

  const { items } = defineProps<{
    items: NavbarItem[]
  }>()

  const openedList = ref("")

  const toggleOpenList = (val: string) => {
    openedList.value = val
  }

  const closeList = () => {
    openedList.value = ""
  }
</script>

<template>
  <NavigationMenuRoot
    v-for="({ label, to, children }, index) in items"
    :key="index"
    :aria-label="label"
    :model-value="openedList"
    @update:model-value="toggleOpenList"
  >
    <NavigationMenuList class="flex gap-4">
      <NavigationMenuItem>
        <DesktopItem v-if="to" :to="to" :label="label" @click="closeList" />
        <NestedDesktopItem
          v-if="children?.length"
          :key="index"
          :sub-items="children"
          :trigger-label="label"
          :item-clicked="closeList"
          :parent-opened="!!openedList"
        />
        <NavigationMenuViewport
          :key="`content-${index}`"
          :data-testid="`${label}-content`"
          :class="
            cn('absolute top-12 mt-3', index >= items.length - 2 && 'right-0')
          "
        />
      </NavigationMenuItem>
    </NavigationMenuList>
  </NavigationMenuRoot>
</template>
