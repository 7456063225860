<script setup lang="ts">
  import { HTMLAttributes } from "vue"
  import Icon from "@/components/ui/icons/Icon.vue"

  const props = defineProps<{
    label: string
    class?: HTMLAttributes["class"]
    actionUrl: string
  }>()
</script>

<template>
  <div class="mt-3 self-end sm:mt-0 sm:self-center">
    <a :href="props.actionUrl" class="ml-4 whitespace-nowrap text-sm"
      >{{ props.label }}
      <Icon name="ArrowRight" size="sm" class="relative -mt-0.5 ml-4 inline"
    /></a>
  </div>
</template>
