import { cva, type VariantProps } from "class-variance-authority"

export { default as Badge } from "./Badge.vue"

export const badgeVariants = cva(
  "inline-flex items-center rounded-full label-text-strong px-3 md:h-[1.625rem] focus:outline-none text-nowrap",
  {
    variants: {
      variant: {
        primary:
          "text-secondary-500 bg-secondary-300 dark:text-secondary-100 dark:bg-secondary-500",
        secondary:
          "text-primary-700 bg-primary-200 dark:text-700 dark:bg-primary-200",
        destructive:
          "text-red-400 bg-red-100 dark:text-red-100 dark:bg-red-500",
      },
    },
    defaultVariants: {
      variant: "primary",
    },
  },
)

export type BadgeVariants = VariantProps<typeof badgeVariants>
