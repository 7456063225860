<script setup lang="ts">
  import { useGetAccount } from "@/api/account/account"
  import { useUpdateCustomer } from "@/api/customer/customer"
  import { type GetAccountResponse } from "@/api/model"
  import { aggregateQueries } from "@/lib/aggregateQueries"
  import { useGetAccountId } from "@/lib/useGetAccountId"
  import { formatCurrency } from "@/lib/utils"
  import { useQueryClient, type UseQueryReturnType } from "@tanstack/vue-query"
  import { computed, defineProps, ref, watch } from "vue"
  import ErrorMessage from "@/components/ErrorMessage.vue"
  import { Alert, AlertDescription } from "@/components/ui/alert"
  import { Badge } from "@/components/ui/badge"
  import { Button } from "@/components/ui/button"
  import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
  } from "@/components/ui/dialog"
  import { RadioCardGroup, RadioCardItem } from "@/components/ui/radio-card"

  defineProps<{
    open: boolean
  }>()

  const emit = defineEmits<{
    "update:open": [value: boolean]
  }>()

  const accountIdQuery = useGetAccountId()
  const accountIds = computed(() => accountIdQuery.data.value?.accountIDs || [])

  const accountQueries = accountIds.value.map((id: number) =>
    useGetAccount(computed(() => id)),
  )

  const { isError, isLoading } = aggregateQueries([
    accountIdQuery,
    ...accountQueries,
  ])

  const accounts = computed(() => {
    return accountQueries.map(
      (query: UseQueryReturnType<GetAccountResponse, Error>) => {
        const account = query.data.value

        return {
          id: account?.accountID?.toString() ?? "",
          accountNumber: account?.accountNumber ?? "",
          supplyAddress: account?.supplyAddress ?? {},
          balance:
            typeof account?.balance === "number"
              ? formatCurrency(account.balance)
              : "",
          isClosed: account?.isClosed,
        }
      },
    )
  })

  const selectedAccountId = ref(
    accountIdQuery.data.value?.accountIDLastVisited?.toString() ?? "",
  )

  watch(
    () => accountIdQuery.data.value?.accountIDLastVisited,
    (newValue) => {
      if (newValue?.toString() !== selectedAccountId.value) {
        selectedAccountId.value = newValue?.toString() ?? ""
      }
    },
    { immediate: true },
  )

  const formatAddress = (address: {
    addressLine1?: string
    addressLine2?: string
    addressLine3?: string
    postcode?: string
  }) => {
    return [
      address?.addressLine1,
      address?.addressLine2,
      address?.addressLine3,
      address?.postcode,
    ]
      .filter(Boolean)
      .join(", ")
  }

  const {
    mutate,
    isPending,
    isError: isUpdateError,
    error,
  } = useUpdateCustomer()

  const queryClient = useQueryClient()

  const handleSubmit = () => {
    const customerId = accountIdQuery.data.value?.customerID
    const currentAccountId =
      accountIdQuery.data.value?.accountIDLastVisited?.toString()

    if (!customerId || !selectedAccountId.value) return
    if (selectedAccountId.value === currentAccountId) {
      emit("update:open", false)
      return
    }

    mutate(
      {
        customerId,
        data: {
          accountIDLastVisited: Number(selectedAccountId.value),
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries()
          emit("update:open", false)
        },
      },
    )
  }
</script>

<template>
  <Dialog :open="open" @update:open="(value) => emit('update:open', value)">
    <DialogContent class="sm:max-w-[540px]">
      <DialogHeader id="account-selection-heading">
        <DialogTitle> Select an account </DialogTitle>
      </DialogHeader>
      <div class="overflow-y-auto">
        <ErrorMessage v-if="isError" />
        <div v-else class="p-[1px]">
          <RadioCardGroup
            v-model="selectedAccountId"
            ariaLabelledby="account-selection-heading"
            @keydown.enter.prevent="handleSubmit"
          >
            <RadioCardItem
              v-for="account in accounts"
              :key="account.id"
              :value="account.id"
              :disabled="isPending"
              name="account-selection"
            >
              <template #header>
                Account: {{ account.accountNumber }}
                <Badge
                  v-if="account.isClosed"
                  variant="destructive"
                  class="ml-2 hidden sm:inline-flex"
                >
                  Closed
                </Badge>
              </template>
              <template #subheader>
                {{ formatAddress(account.supplyAddress) }}
              </template>
              <div class="flex">
                <p>
                  Balance:
                  {{ account.balance }}
                </p>
                <Badge
                  v-if="account.isClosed"
                  variant="destructive"
                  class="ml-auto sm:hidden"
                >
                  Closed
                </Badge>
              </div>
            </RadioCardItem>
          </RadioCardGroup>
        </div>

        <Alert v-if="isUpdateError" variant="error" class="my-4">
          <AlertDescription>
            <template v-if="error?.message === 'Network Error'">
              Cannot reach the So Energy server
            </template>
            <template v-else>Unknown error!</template>
          </AlertDescription>
        </Alert>

        <DialogFooter>
          <Button
            variant="primary"
            :disabled="isLoading || isError"
            :loading="isPending"
            @click="handleSubmit"
          >
            Next
          </Button>
        </DialogFooter>
      </div>
    </DialogContent>
  </Dialog>
</template>
