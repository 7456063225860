<script setup lang="ts">
  import { useGetAlertsAndBanners } from "@/api/alerts/alerts"
  import { useGetAccountId } from "@/lib/useGetAccountId"
  import { ensureResolvedWebsiteUrl } from "@/lib/websiteUrls"
  import TrackingService from "@/services/TrackingService"
  import { whenever } from "@vueuse/core"
  import { computed } from "vue"
  import { Banner } from "@/components/ui/banner"

  const getAccountIdQuery = useGetAccountId()
  const getAlertsAndBannersQuery = useGetAlertsAndBanners(
    getAccountIdQuery.accountId,
  )
  const banner = computed(() => {
    return getAlertsAndBannersQuery.data.value?.marketingBanner
  })

  const onBannerButtonClicked = (link: string) => {
    TrackingService.sendEvent("Marketing Banner - Action Click", {
      marketingBannerName: banner.value?.name,
      link,
    })
    const normalizedUrl = ensureResolvedWebsiteUrl(link)
    window.location.assign(normalizedUrl)
  }

  whenever(
    banner,
    (banner) => {
      TrackingService.sendEvent("Marketing Banner - Impression", {
        marketingBannerName: banner.name,
      })
    },
    { once: true },
  )
</script>

<template>
  <div v-if="banner" class="space-y-4 sm:space-y-6">
    <Banner
      :label="banner.buttonText"
      :title="banner.title"
      :content="banner.subtitle"
      :illustration="banner.illustrationName"
      :showCloseButton="false"
      @actionButtonClick="onBannerButtonClicked(banner.buttonLink)"
    />
  </div>
</template>
