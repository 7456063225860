import { MarketingBannerName } from "@/api/model"
import { canInitializeDatadog } from "@/plugins/datadog/datadogHelper.ts"
import { datadogRum } from "@datadog/browser-rum"

type EventTypes = {
  "Marketing Banner - Action Click": {
    marketingBannerName: MarketingBannerName | undefined
    link: string
  }
  "Marketing Banner - Impression": {
    marketingBannerName: MarketingBannerName
  }
  "Alert - Dismiss Click": {
    alertName: string
  }
  "Alert - Impression": {
    alertName: string
  }
  "Alert - Action Click": {
    alertName: string
    linkTo: string
  }
  "login-auth-attempt": {
    email: string
    extendedSession: boolean
  }
  "login-auth-successful": {
    redirectUrl: string | undefined
    loginIntoNexus: boolean
  }
  "password-reset-email-success": {
    email: string
  }
}
type EventTypesWithoutMetadata =
  | "password-reset-update-success"
  | "move-in-typeform-redirect"
  | "redirectToLogin"
  | "loginRedirectSuccessful"
  | "login-fetch-data-successful"

function sendEvent(eventName: EventTypesWithoutMetadata): void
function sendEvent<T extends keyof EventTypes>(
  eventName: T,
  metadata: EventTypes[T],
): void

function sendEvent<T extends keyof EventTypes>(
  eventName: T | string,
  metadata?: EventTypes[T],
): void {
  if (canInitializeDatadog()) {
    datadogRum.addAction(eventName, metadata ?? {})
  }
}

export default {
  sendEvent,
}
