<script setup lang="ts">
  import { TooltipProvider, TooltipRoot } from "radix-vue"

  import TooltipContent from "./PlainTooltipContent.vue"
  import TooltipTrigger from "./PlainTooltipTrigger.vue"

  interface TooltipProps {
    defaultOpen?: boolean
    open?: boolean
  }

  const props = withDefaults(defineProps<TooltipProps>(), {
    defaultOpen: false,
    open: undefined,
  })

  defineOptions({
    inheritAttrs: false,
  })
</script>

<template>
  <TooltipProvider>
    <TooltipRoot
      :delay-duration="400"
      data-testid="plain-tooltip"
      disable-closing-trigger
      v-bind="props"
    >
      <TooltipTrigger>
        <slot name="trigger" />
      </TooltipTrigger>
      <TooltipContent>
        <slot />
      </TooltipContent>
    </TooltipRoot>
  </TooltipProvider>
</template>
