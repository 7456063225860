interface GTMEvent {
  event: string
  userId?: string
  [key: string]: unknown
}

export default {
  sendCustomEvent(eventData: GTMEvent): void {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(eventData)
  },
}
