/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Portal API Gateway
 * OpenAPI spec version: 2.0.0
 */

/**
 * Current account state for energy supply
 */
export type SupplyState = (typeof SupplyState)[keyof typeof SupplyState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SupplyState = {
  onboarding: "onboarding",
  enrolmentRejected: "enrolmentRejected",
  enrolmentObjected: "enrolmentObjected",
  enrolmentCancelled: "enrolmentCancelled",
  onboardingPartiallyRejected: "onboardingPartiallyRejected",
  onboardingPartiallyObjected: "onboardingPartiallyObjected",
  onboardingPartiallyCancelled: "onboardingPartiallyCancelled",
  onboardedRecently: "onboardedRecently",
  onSupply: "onSupply",
  onboardingAdditionalMeterpoint: "onboardingAdditionalMeterpoint",
  onboardingAdditionalMeterpointFailed: "onboardingAdditionalMeterpointFailed",
  partialLossInitiated: "partialLossInitiated",
  lossInitiated: "lossInitiated",
  lossComplete: "lossComplete",
  futureMoveOut: "futureMoveOut",
  movedOut: "movedOut",
  unknown: "unknown",
} as const
