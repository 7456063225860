<script setup lang="ts">
  import { isAbsoluteUrl } from "@/lib/isAbsoluteUrl"
  import { computed } from "vue"

  const props = defineProps<{
    to: string
    target?: string
  }>()
  const isAnchorElement = computed(
    () => props.to === "javascript:;" || isAbsoluteUrl(props.to),
  )
  const linkAttributes = computed(() =>
    isAnchorElement.value ? { href: props.to } : {},
  )
</script>

<template>
  <component
    :is="isAnchorElement ? 'a' : 'router-link'"
    v-bind="linkAttributes"
    :to="props.to"
    :target="props.target"
  >
    <slot></slot>
  </component>
</template>
