<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import {
    TooltipArrow,
    TooltipContent,
    TooltipPortal,
    useForwardPropsEmits,
    type TooltipContentEmits,
    type TooltipContentProps,
  } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"

  defineOptions({
    inheritAttrs: false,
  })

  const props = withDefaults(
    defineProps<TooltipContentProps & { class?: HTMLAttributes["class"] }>(),
    {
      sideOffset: 4,
    },
  )

  const emits = defineEmits<TooltipContentEmits>()

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })

  const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <TooltipPortal>
    <TooltipContent
      v-bind="{ ...forwarded, ...$attrs }"
      :class="
        cn(
          'label-text animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 radius-sm z-50 max-w-[200px] overflow-hidden rounded border bg-secondary-100 p-3 text-center shadow-md dark:border-0 dark:bg-secondary-600 dark:text-secondary-100',
          props.class,
        )
      "
      data-testid="plain-tooltip-content"
    >
      <slot />

      <TooltipArrow
        :width="25"
        :height="9"
        style="transform: translateY(-1.5px)"
        data-testid="plain-tooltip-arrow"
      >
        <svg viewBox="0 0 25 9">
          <path
            d="M0 1 25 1"
            class="stroke-secondary-100 dark:stroke-secondary-600"
          />
          <path
            d="M0 1C5.5 1 8.5 5 8.5 5C8.5 5 10.5 8 12.5 8C14.5 8 16.5 5 16.5 5C16.5 5 19.5 1 25 1"
            class="fill-secondary-100 stroke-border dark:fill-secondary-600 dark:stroke-none"
          />
        </svg>
      </TooltipArrow>
    </TooltipContent>
  </TooltipPortal>
</template>
