import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function preventBodyScroll(preventScroll: boolean) {
  if (preventScroll) {
    document.body.classList.add("overflow-hidden")
  } else {
    document.body.classList.remove("overflow-hidden")
  }
}

export const safeJSONParse = (string: string): unknown => {
  try {
    return JSON.parse(string)
  } catch (err) {
    return {
      details: string,
    }
  }
}

export const formatCurrency = (amountInPounds: number) =>
  new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  }).format(amountInPounds)

export const formatCurrencyInPence = (
  amountInPence: number,
  fractionDigits = 2,
) => {
  const penceSymbol = "p"
  const amount = new Intl.NumberFormat("en-GB", {
    style: "decimal",
    maximumFractionDigits: fractionDigits,
  }).format(amountInPence)

  return `${amount}${penceSymbol}`
}
