<script setup lang="ts">
  import {
    useDeactivateAlert,
    useGetAlertsAndBanners,
  } from "@/api/alerts/alerts"
  import { AlertName, AlertSeverity } from "@/api/model"
  import { useGetAccountId } from "@/lib/useGetAccountId"
  import { cn } from "@/lib/utils"
  import TrackingService from "@/services/TrackingService"
  import { computed, ref, watch } from "vue"
  import { Alert as AlertBox, AlertVariants } from "@/components/ui/alert"
  import AlertAction from "@/components/ui/alert/AlertAction.vue"
  import AlertClose from "@/components/ui/alert/AlertClose.vue"
  import { AlertDescription, AlertTitle } from "@/components/ui/alert/index.ts"
  import Button from "@/components/ui/button/Button.vue"

  const severityToVariantMap: {
    [K in AlertSeverity]: AlertVariants["variant"]
  } = {
    [AlertSeverity.info]: "info",
    [AlertSeverity.warning]: "warning",
    [AlertSeverity.critical]: "error",
    [AlertSeverity.success]: "success",
  } as const

  const SUMMARY_LENGTH = 2
  const isOpen = ref(false)
  const dismissible = false // Temporary placeholder until BE endpoint contains dismissible property

  const getAccountIdQuery = useGetAccountId()
  const getAlertsAndBannersQuery = useGetAlertsAndBanners(
    getAccountIdQuery.accountId,
  )

  const hiddenAlerts = ref<string[]>([])

  const allAlertItems = computed(
    () => getAlertsAndBannersQuery.data.value?.alerts ?? [],
  )

  const alertsToRender = computed(() =>
    isOpen.value
      ? allAlertItems.value
      : allAlertItems.value.slice(0, SUMMARY_LENGTH),
  )

  const deactivateAlertMutation = useDeactivateAlert({
    mutation: {
      onSuccess: () => {
        getAlertsAndBannersQuery.refetch()
      },
    },
  })

  const handleAlertClose = (alertName: string) => {
    hiddenAlerts.value.push(alertName)
    if (!getAccountIdQuery.accountId.value) return

    deactivateAlertMutation.mutate({
      accountId: getAccountIdQuery.accountId.value,
      data: { name: alertName as AlertName },
    })

    TrackingService.sendEvent("Alert - Dismiss Click", {
      alertName,
    })
  }

  const onAlertActionClicked = (alertName: string, linkTo: string) => {
    TrackingService.sendEvent("Alert - Action Click", {
      alertName,
      linkTo,
    })
  }

  watch(alertsToRender, (alerts) => {
    alerts.forEach((alert) => {
      TrackingService.sendEvent("Alert - Impression", {
        alertName: alert.name,
      })
    })
  })
</script>

<template>
  <div v-if="alertsToRender.length" class="space-y-4 sm:space-y-6">
    <div
      v-for="(alert, index) in alertsToRender"
      :key="alert.title"
      :class="
        cn(
          !isOpen &&
            index >= SUMMARY_LENGTH - 1 &&
            'max-h-17 overflow-hidden [mask-image:linear-gradient(rgba(0,0,0,1)_2rem,rgba(0,0,0,0))]',
        )
      "
    >
      <AlertBox :variant="severityToVariantMap[alert?.severity || 'warning']">
        <AlertTitle>{{ alert.title }}</AlertTitle>
        <AlertClose
          v-if="dismissible"
          data-dd-action-name="Dismiss alert"
          @close="() => handleAlertClose(alert.name)"
        />
        <AlertDescription>
          {{ alert.subtitle }}
        </AlertDescription>
        <template v-if="alert.buttonLink && alert.buttonText" #action>
          <AlertAction
            data-dd-action-name="Alert action"
            :label="alert.buttonText"
            :action-url="alert.buttonLink"
            @click="onAlertActionClicked(alert.name, alert.buttonLink)"
          />
        </template>
      </AlertBox>
    </div>
    <div v-if="alertsToRender.length >= SUMMARY_LENGTH" class="text-center">
      <Button variant="outline" @click="isOpen = !isOpen">{{
        isOpen ? "Hide Notifications" : "Show All Notifications"
      }}</Button>
    </div>
  </div>
</template>
