import {
  SEASONAL_SUMMER_START_MONTH,
  SEASONAL_WINTER_START_MONTH,
} from "@/constants/date.ts"
import { Dayjs } from "dayjs"

export const isSummerMonth = (date: Dayjs): boolean => {
  const month = date.month() + 1
  return (
    month >= SEASONAL_SUMMER_START_MONTH && month < SEASONAL_WINTER_START_MONTH
  )
}
