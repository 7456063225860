import { useCookies } from "@vueuse/integrations/useCookies"
import { computed, onMounted, onUnmounted, ref } from "vue"

interface Data {
  title: string
  message: string
  pages: string[]
}

const defaultData: Partial<Data> = {
  title: "Under maintenance",
  message:
    "We are doing some essential maintenance on our website. We apologise for any inconvenience this may cause.",
}

const maintenanceCookieKey = "website_maintenanceBanner"

export function usePageLevelMaintenance() {
  const data = ref<Data>()
  const isUnderMaintenance = computed(() => !!data.value)

  const cookies = useCookies([maintenanceCookieKey])

  const update = () => {
    const maintenanceCookie = cookies.get(maintenanceCookieKey)

    data.value = maintenanceCookie?.pages?.includes(location.pathname)
      ? {
          ...defaultData,
          ...(maintenanceCookie ?? {}),
        }
      : undefined
  }

  onMounted(() => {
    cookies.addChangeListener(update)
    update()
  })
  onUnmounted(() => {
    cookies.removeChangeListener(update)
  })

  return {
    data,
    isUnderMaintenance,
  }
}
