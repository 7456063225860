import dayjs, { Dayjs } from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"

dayjs.extend(advancedFormat)

export const DateFormats = {
  default: "D MMM YYYY",
  long: "Do MMMM YYYY",
} as const

export const formatDate = (
  date: Dayjs | string,
  format: keyof typeof DateFormats = "default",
): string => {
  return dayjs(date).format(DateFormats[format])
}
