import { useGetAccount } from "@/api/account/account"
import { authSession } from "@/authentication/authenticationService"
import { useGetAccountId } from "@/lib/useGetAccountId.ts"
import { runOnceCookieCategoryAccepted } from "@/services/cookieConsent/CookieConsentPlugin"
import GoogleTagManager from "@/services/GoogleTagManager"
import TrackingService from "@/services/TrackingService"
import { datadogRum } from "@datadog/browser-rum"
import { computed, watch } from "vue"

const setAnalyticsUser = (userId: string) => {
  GoogleTagManager.sendCustomEvent({
    event: "identify_user",
    userId,
  })

  runOnceCookieCategoryAccepted("statistics", () => {
    datadogRum.setUser({
      id: userId,
    })
  })
}

const clearAnalyticsUser = () => {
  GoogleTagManager.sendCustomEvent({
    event: "unidentify_user",
    userId: undefined,
  })
  datadogRum.clearUser()
}

export function useAnalyticsTracking() {
  const accountIdQuery = useGetAccountId()
  const accountQuery = useGetAccount(accountIdQuery.accountId)
  const accountNumber = computed<string | undefined>(
    () => accountQuery.data.value?.accountNumber,
  )
  const { isAuthenticated } = authSession

  watch(
    accountNumber,
    (newAccountNumber) => {
      if (!newAccountNumber) return
      setAnalyticsUser(newAccountNumber)
      TrackingService.sendEvent("login-fetch-data-successful")
    },
    { immediate: true },
  )

  watch(
    isAuthenticated,
    (isAuthenticated) => {
      if (!isAuthenticated) {
        clearAnalyticsUser()
      }
    },
    { immediate: true },
  )
}
