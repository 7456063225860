<script setup lang="ts">
  import { useGetAccountStates } from "@/api/account-state/account-state"
  import { GetUnbilledQueryError, useGetUnbilled } from "@/api/account/account"
  import { BillGenerationState } from "@/api/model/billGenerationState"
  import { ErrorCode } from "@/api/model/errorCode"
  import { ExternalRoute } from "@/constants/externalRoute.ts"
  import { aggregateQueries } from "@/lib/aggregateQueries"
  import { useGetAccountId } from "@/lib/useGetAccountId"
  import ErrorReporter from "@/services/ErrorReporter"
  import dayjs from "dayjs"
  import { computed } from "vue"
  import { Button } from "@/components/ui/button"
  import {
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    CardTitle,
  } from "@/components/ui/card"
  import Icon from "@/components/ui/icons/Icon.vue"
  import PlainTooltip from "@/components/ui/plain-tooltip/PlainTooltip.vue"

  import EnergyUsageCardSkeleton from "./EnergyUsageCardSkeleton.vue"

  const blank = "--.--"
  const accountIdQuery = useGetAccountId()
  const unbilledQuery = useGetUnbilled(accountIdQuery.accountId, {
    query: {
      throwOnError: (error: GetUnbilledQueryError) => {
        const errorCode = error?.response?.data?.errorCode
        const isExpectedServerError = errorCode === ("notFound" as ErrorCode)
        if (!isExpectedServerError) ErrorReporter.report(error)
        return false
      },
    },
  })
  const accountStateQuery = useGetAccountStates(accountIdQuery.accountId)

  const { isError, isSuccess, isFetching, isLoading } = aggregateQueries([
    accountIdQuery,
    unbilledQuery,
    accountStateQuery,
  ])

  const summary = computed(() => {
    const nextBill = unbilledQuery.data?.value?.nextBill

    return {
      dateRange: nextBill
        ? `${dayjs(nextBill.startDate).format("D MMM")} - ${dayjs().format("D MMM YYYY")}`
        : "",
      totalCostInPounds: nextBill?.totalCostInPounds.toFixed(2) ?? blank,
      totalConsumptionInKwh:
        nextBill?.totalConsumptionInKwh?.toFixed(2) ?? blank,
      electricityUsage: {
        isSupplied: nextBill?.electricityUsage.isSupplied,
        usageCostInPounds:
          nextBill?.electricityUsage.usageCostInPounds.toFixed(2),
        consumptionInKwh:
          nextBill?.electricityUsage.consumptionInKwh.toFixed(2),
      },
      gasUsage: {
        isSupplied: nextBill?.gasUsage.isSupplied,
        usageCostInPounds: nextBill?.gasUsage.usageCostInPounds.toFixed(2),
        consumptionInKwh: nextBill?.gasUsage.consumptionInKwh.toFixed(2),
      },
    }
  })

  const unableToGenerateEstimate = computed(() => {
    const nextBill = unbilledQuery.data?.value?.nextBill

    const hasNoNextBill = !nextBill
    const noBillWithin31Days =
      accountStateQuery.data?.value?.billGeneration ===
      BillGenerationState.noBillWithin31Days
    const unableToEstimateSuppliedElectricityUsage =
      nextBill?.electricityUsage.isSupplied &&
      nextBill?.electricityUsage.unableToEstimate
    const unableToEstimateSuppliedGasUsage =
      nextBill?.gasUsage.isSupplied && nextBill?.gasUsage.unableToEstimate

    return (
      accountStateQuery.data?.value?.billGeneration ===
        BillGenerationState.noBillNewAccount ||
      hasNoNextBill ||
      noBillWithin31Days ||
      unableToEstimateSuppliedElectricityUsage ||
      unableToEstimateSuppliedGasUsage
    )
  })
</script>

<template>
  <EnergyUsageCardSkeleton v-if="isLoading" />
  <Card v-else data-testid="your-usage-card">
    <CardHeader>
      <CardTitle>Energy Usage</CardTitle>
    </CardHeader>
    <CardContent>
      <p
        v-if="
          isError &&
          !isFetching &&
          accountStateQuery.data?.value?.billGeneration !==
            BillGenerationState.noBillNewAccount
        "
        class="body-text-strong inline-flex items-center"
      >
        <Icon name="Warning" size="lg" class="mr-1" />
        <span> Failed to load </span>
      </p>
      <div v-else-if="unableToGenerateEstimate">
        <p>
          It looks like we don't have enough reads to generate an estimate.
          Submit regular reads to understand your usage better.
        </p>
        <div class="space-y-0.5">
          <p class="body-text-strong mb-1 mt-4 sm:mt-2">Total</p>
          <p>£{{ blank }} | {{ blank }} kWh</p>
        </div>
      </div>
      <div v-else>
        <div class="space-y-0.5">
          <p class="body-text-strong">
            Total ({{ summary.dateRange }})
            <PlainTooltip>
              We've calculated your estimated usage for this period using your
              previous meter reads.
            </PlainTooltip>
          </p>
          <p class="body-text-strong text-secondary-500">Estimated Usage</p>
          <p>
            {{
              `£${summary.totalCostInPounds} | ${summary.totalConsumptionInKwh} kWh`
            }}
          </p>
        </div>

        <div class="my-4 flex">
          <div v-if="summary.electricityUsage.isSupplied" class="w-full">
            <dl class="relative pl-5">
              <Icon
                name="Electricity"
                class="absolute left-0 top-1 row-span-3 stroke-primary-500 sm:top-1.5"
              />
              <dt class="body-text-strong">Electricity</dt>
              <dl data-testid="next-bill-electricity-usage-cost-in-pounds">
                £{{ summary.electricityUsage.usageCostInPounds }}
              </dl>
              <dl data-testid="next-bill-electricity-consumption-in-kwh">
                {{ `${summary.electricityUsage.consumptionInKwh} kWh` }}
              </dl>
            </dl>
          </div>
          <div v-if="summary.gasUsage.isSupplied" class="w-full">
            <dl class="relative pl-5">
              <Icon
                name="Gas"
                class="absolute left-0 top-1 row-span-3 stroke-secondary-400 sm:top-1.5"
              />
              <dt class="body-text-strong">Gas</dt>
              <dl data-testid="next-bill-gas-usage-cost-in-pounds">
                £{{ summary.gasUsage.usageCostInPounds }}
              </dl>
              <dl data-testid="next-bill-gas-consumption-in-kwh">
                {{ `${summary.gasUsage.consumptionInKwh} kWh` }}
              </dl>
            </dl>
          </div>
        </div>
        <p class="label-text">
          All rates incl. VAT, excluding standing charges.
        </p>
      </div>
    </CardContent>
    <CardFooter>
      <Button variant="tonal" :as-child="isSuccess">
        <span v-if="!isSuccess">View Energy Usage</span>
        <a v-else :href="ExternalRoute.usage">View Energy Usage</a>
      </Button>
    </CardFooter>
  </Card>
</template>
