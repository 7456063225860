/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Portal API Gateway
 * OpenAPI spec version: 2.0.0
 */
import {
  useMutation,
  useQuery,
  type MutationFunction,
  type QueryFunction,
  type QueryKey,
  type UseMutationOptions,
  type UseMutationReturnType,
  type UseQueryOptions,
  type UseQueryReturnType,
} from "@tanstack/vue-query"
import { unref, type MaybeRef } from "vue"

import { customInstance, type ErrorType } from ".././client"
import type { AlertsResponse, DeactivateAlertRequest, Error } from ".././model"
import { useMutationOptions } from ".././mutationOptions"
import { useQueryOptions } from ".././queryOptions"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary Get the alerts and marketing banners that are active for this account
 */
export const getAlertsAndBanners = (
  accountId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  accountId = unref(accountId)

  return customInstance<AlertsResponse>(
    {
      url: `/api/v1/accounts/${accountId}/alerts-banners`,
      method: "GET",
      signal,
    },
    options,
  )
}

export const getGetAlertsAndBannersQueryKey = (accountId: MaybeRef<number>) => {
  return ["api", "v1", "accounts", accountId, "alerts-banners"] as const
}

export const useGetAlertsAndBannersQueryOptions = <
  TData = Awaited<ReturnType<typeof getAlertsAndBanners>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAlertsAndBanners>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetAlertsAndBannersQueryKey(accountId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAlertsAndBanners>>
  > = ({ signal }) => getAlertsAndBanners(accountId, requestOptions, signal)

  const customOptions = useQueryOptions(
    { ...queryOptions, queryKey, queryFn },
    { accountId },
  )

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getAlertsAndBanners>>,
    TError,
    TData
  >
}

export type GetAlertsAndBannersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAlertsAndBanners>>
>
export type GetAlertsAndBannersQueryError = ErrorType<Error>

/**
 * @summary Get the alerts and marketing banners that are active for this account
 */
export const useGetAlertsAndBanners = <
  TData = Awaited<ReturnType<typeof getAlertsAndBanners>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAlertsAndBanners>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAlertsAndBannersQueryOptions(accountId, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

/**
 * @summary Deactivate an alert
 */
export const deactivateAlert = (
  accountId: MaybeRef<number>,
  deactivateAlertRequest: MaybeRef<DeactivateAlertRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  accountId = unref(accountId)
  deactivateAlertRequest = unref(deactivateAlertRequest)

  return customInstance<AlertsResponse>(
    {
      url: `/api/v1/accounts/${accountId}/alerts/deactivate`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: deactivateAlertRequest,
    },
    options,
  )
}

export const useDeactivateAlertMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deactivateAlert>>,
    TError,
    { accountId: number; data: DeactivateAlertRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof deactivateAlert>>,
  TError,
  { accountId: number; data: DeactivateAlertRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deactivateAlert>>,
    { accountId: number; data: DeactivateAlertRequest }
  > = (props) => {
    const { accountId, data } = props ?? {}

    return deactivateAlert(accountId, data, requestOptions)
  }

  const customOptions = useMutationOptions({ ...mutationOptions, mutationFn })

  return customOptions
}

export type DeactivateAlertMutationResult = NonNullable<
  Awaited<ReturnType<typeof deactivateAlert>>
>
export type DeactivateAlertMutationBody = DeactivateAlertRequest
export type DeactivateAlertMutationError = ErrorType<Error>

/**
 * @summary Deactivate an alert
 */
export const useDeactivateAlert = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deactivateAlert>>,
    TError,
    { accountId: number; data: DeactivateAlertRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof deactivateAlert>>,
  TError,
  { accountId: number; data: DeactivateAlertRequest },
  TContext
> => {
  const mutationOptions = useDeactivateAlertMutationOptions(options)

  return useMutation(mutationOptions)
}
