<script setup lang="ts">
  import { AlertSeverity } from "@/api/model"
  import {
    Alert,
    AlertDescription,
    AlertTitle,
  } from "@/components/ui/alert/index.ts"

  import { usePageLevelMaintenance } from "./usePageLevelMaintenance"

  const { data, isUnderMaintenance } = usePageLevelMaintenance()
</script>

<template>
  <Alert v-if="isUnderMaintenance" :variant="AlertSeverity.warning">
    <AlertTitle>{{ data?.title }}</AlertTitle>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <AlertDescription><div v-html="data?.message"></div></AlertDescription>
  </Alert>
</template>
