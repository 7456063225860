/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Portal API Gateway
 * OpenAPI spec version: 2.0.0
 */

/**
 * Current account state for bill generation
 */
export type BillGenerationState =
  (typeof BillGenerationState)[keyof typeof BillGenerationState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillGenerationState = {
  noBillNewAccount: "noBillNewAccount",
  billSentWithin31Days: "billSentWithin31Days",
  noBillWithin31Days: "noBillWithin31Days",
  billingSuppressed: "billingSuppressed",
  noBillAndBillingRecentlyUnsuppressed: "noBillAndBillingRecentlyUnsuppressed",
  finalBillSent: "finalBillSent",
  unknown: "unknown",
} as const
